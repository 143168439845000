import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "add-event-to-device-calendar"
    }}>{`Add Event to Device Calendar`}</h1>
    <p>{`Members and staff can download an .ics file to their own device, so that an event appears as part of their device's internal calendar application.`}</p>
    <h2 {...{
      "id": "add-event-to-device-calendar-1"
    }}>{`Add Event to Device Calendar`}</h2>
    <ol>
      <li parentName="ol">{`Visit event page from either the staff or public view`}</li>
      <li parentName="ol">{`For staff, select Download calendar invite`}</li>
      <li parentName="ol">{`For public, select add to calendar`}</li>
      <li parentName="ol">{`Upload .ics to calendar service of choice`}</li>
    </ol>
    <h2 {...{
      "id": "how-to-import-an-ics-file"
    }}>{`How to Import An .ics File`}</h2>
    <a href="https://support.google.com/calendar/answer/37118?co=GENIE.Platform%3DDesktop&hl=en">To Google Calendar</a>
    <a href="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c">To Outlook Calendar</a>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/VJLNGgysFSo" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Seamlessly add an event to your personal calendar using an ICS calendar file.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      